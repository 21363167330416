<template>
  <v-main>
    <v-row v-if="loading" class="mx-16">
      <v-col v-for="index in 6" :key="index" cols="2">
        <v-skeleton-loader
            v-bind="skeletonAttrs"
            type="list-item-avatar, list-item-three-line, divider, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mx-md-16" v-else>
      <v-col :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
        <h3 class="my-3 secondary-prosk">
          {{ $t("morePopular") }}
        </h3>
      </v-col>
      <v-col cols="12" class="mx-0 py-0">
        <carousel :scrollPerPage="false" :perPage="1" :perPageCustom="[[768, 2], [1024, 4]]">
          <slide v-for="(item, n) in data" :key="n" class="w-100">
            <v-card
              :loading="loading"
              class="pb-2 ml-md-4 mx-auto mx-md-0 mb-2 d-flex flex-column justify-content-between card"
              :width="$vuetify.breakpoint.mobile ? '90%' : '350px'"
              height="564"
            >
              <v-card-title @click="goToUserProfile(item)">
                <v-row>
                  <v-col cols="3">
                    <v-avatar width="4rem" height="4rem" class="mr-2">
                      <img
                          v-if="item.profile_img"
                          :src="item.profile_img"
                          class="img-fluid rounded-circle border border-dark avatar-50"
                          alt="user"
                      />
                      <img
                          v-else
                          src="@/assets/images/page-img/avatar.png"
                          class="img-fluid rounded-circle border border-dark avatar-50"
                          alt="user"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="d-flex">
                      <p class="p-posted">{{ $t("postedBy") }}</p>
                      <v-rating
                          :value="item.rating"
                          half-increments
                          readonly
                          small
                          background-color="#59D79D"
                          color="#F2D022"
                          dense
                          class="my-0"
                          style="margin-left: 32px; margin-top: -12px;"
                      ></v-rating>
                    </div>
                    <p class="name">{{ item.first_name }} {{ item.last_name }}</p>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-img
                    min-height="220"
                    max-height="220"
                    class="round"
                    :src="item.banner_img ? item.banner_img : 'https://mdbootstrap.com/img/new/standard/nature/187.jpg'"
                  ></v-img>
              <v-card-text>
                <v-row>
                  <span class="green-prosk font-size-14 ml-2">{{ item.count_works_done }} {{ $t('worksDone') }} </span>
                </v-row>
                <h5 class="my-4 text-subtitle-1 green-prosk font-weight-bold">{{ item.category_name[0] }}</h5>
                <p class="mt-3 secondary-prosk"></p>
                <p class="secondary-prosk">{{ item.description }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="text green-prosk"
                  text
                  @click="goToUserProfile(item)"
                >
                  {{ $t('goToProfile') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </slide>
        </carousel>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import { Carousel, Slide } from 'vue-carousel';

import errorDialog from '@/components/socialvue/mixins/errorDialog';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
export default {
  name: 'ProskerFeatured',
  mixins: [errorDialog, goToUserProfile],
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      data: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true
    };
  },
  created () {
    this.getUsers();
  },
  methods: {
    getUsers () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users?filters=rating=4|5|per_page=8&sortBy=created_at,desc`;
      axios.get(url).then((response) => {
        this.data = response.data.data;
        this.loading = false;
      }).catch((error) => {
        this.showError(error);
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
  .card {
    border-radius: 12px;
    padding: 12px;
  }

  .round {
    border-radius: 12px;
    width: 100%;
  }

  .p-posted {
    color: #59D79D;
    font-size: .8rem !important;
  }

  .name {
    color: #3F44A6;
    font-size: 1.2rem !important;
    margin-top: -20px;
  }
</style>
